export const cartNoteUpdateMutation = `
mutation CartNoteUpdate($cartId: ID!, $note: String!) {
    cartNoteUpdate(cartId: $cartId, note: $note) {
        cart {
            id
            note
        }
    }
}
`;
