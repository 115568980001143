import { getRangeFilters } from "./queryUtils";
import { DEFAULT_ORDERS_PER_PAGE } from "../../utils/constants/constants";

export const SHOPIFY_ADMIN = {
  url: `https://${process.env.GATSBY_SHOPIFY_STORE_URL_PROXY}/admin/api/${process.env.GATSBY_SHOPIFY_API_VERSION}/graphql.json`,
  headers: {
    "Content-Type": "application/json",
    "X-Shopify-Access-Token": process.env.SHOPIFY_SHOP_PASSWORD ?? "",
  },
};

export const SHOPIFY_STOREFRONT = {
  url: `https://${process.env.GATSBY_SHOPIFY_STORE_URL_PROXY}/api/${process.env.GATSBY_SHOPIFY_API_VERSION}/graphql.json`,
  headers: {
    "Content-Type": "application/json",
    "X-Shopify-Storefront-Access-Token":
      process.env.GATSBY_STOREFRONT_ACCESS_TOKEN ?? "",
  },
};

export const GATSBY_SALT = `${process.env.GATSBY_SALT}`;

export const SHOPIFY_STOREFRONT_QUERIES = {
  associateQuery: `mutation CartBuyerIdentityUpdate($cartId: ID!, $customerAccessToken: String) {
    cartBuyerIdentityUpdate(
        cartId: $cartId
        buyerIdentity: { customerAccessToken: $customerAccessToken }
    ) {
        cart {
            id
            buyerIdentity {
                customer {
                    id
                    email
                    firstName
                    lastName
                }
            }
        }
        userErrors {
            code
            field
            message
        }
    }
}`,
  shippingAddressUpdateQuery: `mutation CartBuyerIdentityUpdate(
    $cartId: ID!
    $buyerIdentity: CartBuyerIdentityInput!
  ) {
    cartBuyerIdentityUpdate(cartId: $cartId, buyerIdentity: $buyerIdentity) {
        cart {
            id
        }
        userErrors {
            code
            field
            message
        }
    }
}`,
  loginQuery: `mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
    customerAccessTokenCreate(input: $input) {
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors { field, message, code }
    }
  }`,
  createCustomerQuery: `mutation customerCreate($input: CustomerCreateInput!) {
    customerCreate(input: $input) {
      customer {
        id
      }
      customerUserErrors { field, message, code }
    }
  }`,
  getCustomerOrdersQuery: (startCursor: string, endCursor: string) => {
    return `
      query ($customerAccessToken: String!) {
        customer(customerAccessToken: $customerAccessToken) {
          orders(reverse: true, ${getRangeFilters(
      DEFAULT_ORDERS_PER_PAGE,
      endCursor,
      startCursor,
    )}) {
            nodes {
              id
              name
              currencyCode
              subtotalPrice {
                amount
              }
              totalShippingPrice {
                amount
              }
              totalTax {
                amount
              }
              totalPrice {
                amount
              }
              email
              financialStatus
              fulfillmentStatus
              orderNumber
              processedAt 
              shippingAddress {
                address1
                address2
                city
                company
                country
                name
                phone
                provinceCode
                zip
              }
              discountApplications(first: 5) {
                nodes {
                  allocationMethod
                  targetSelection
                  targetType
                  value {
                    ... on MoneyV2 {
                      __typename
                      amount
                      currencyCode
                    }
                  }
                  ... on AutomaticDiscountApplication {
                    __typename
                    title
                    value {
                      ... on MoneyV2 {
                        __typename
                        amount
                      }
                    }
                  }
                  ... on ManualDiscountApplication {
                    title
                    value {
                      ... on MoneyV2 {
                        __typename
                        amount
                      }
                    }
                    description
                  }
                  ... on DiscountCodeApplication {
                    __typename
                    value {
                      ... on MoneyV2 {
                        __typename
                        amount
                      }
                    }
                    code
                  }
                  ... on ScriptDiscountApplication {
                    __typename
                    title
                    value {
                      ... on MoneyV2 {
                        __typename
                        amount
                      }
                    }
                  }
                }
              }
            }
            pageInfo {
              hasNextPage
              endCursor
              hasPreviousPage
              startCursor
            }
          }
        }
      }
    `;
  },
  getProductByShopifyIdQuery: `query getProductById($id: ID!) {
    product(id: $id) {
      id
      title
      description
      productType
      handle
      options {
        name
        values
      }
      membershipYearsNeeded: metafield(
        key: "min_membership_years"
        namespace: "mdrt"
      ) {
        value
      }
      membershipLevel: metafield(namespace: "mdrt", key: "membership_level") {
        value
        type
      }
      membershipType: metafield(namespace: "mdrt", key: "membership_type") {
        value
        type
      }
      compareAtPriceRange {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      priceRange {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      featuredImage {
        altText
        height
        id
        url
        width
      }
      variants(first: 5) {
        edges {
          node {
            id
            image {
              altText
              height
              url
              width
            }
            compareAtPrice {
              amount
              currencyCode
            }
            price {
              amount
              currencyCode
            }
            sku
            title
            membershipYearsNeeded: metafield(
              key: "min_membership_years"
              namespace: "mdrt"
            ) {
              value
            }
            membershipLevel: metafield(namespace: "mdrt", key: "membership_level") {
              value
              type
            }
            membershipType: metafield(namespace: "mdrt", key: "membership_type") {
              value
              type
            }
          }
        }
      }
    }
  }`,
  getVariantAvailabilityQuery: `query getProductById($id: ID!) {
    product(id: $id) {
      id
      title
      variants(first: 5) {
        edges {
          node {
            id
            quantityAvailable
            availableForSale
            requiresShipping
          }
        }
      }
    }
  }`,
};

export const SHOPIFY_ADMIN_QUERIES = {
  glcodeQuery: `{
    orders(first: 3) {
      edges {
          node {
              id
              lineItems(first:10) {
                  nodes {
                      id
                      product {
                          id
                          title
                          metafields(first: 10) {
                              nodes {
                                  key
                                  value
                              }
                          }
                      }
                  }
              }
          }
      }
  }
}`,
};
