import { Metafield } from "../types/shopify/global.types";
import { Cursor, PageInfoFull } from "../types/shopify/admin.types";
import {
  OrderListItem,
  Product,
  ProductOptionWithTranslations,
  Translation,
} from "../types/internal.types";
import { AdditionalInfo } from "../services/shopify/types";
import { MonetaryAmount } from "../types/cart/monetoryValue";

export type NodesProduct = {
  nodes: Product[];
};

export interface Collection {
  id: string;
  title: string;
  metafields: Metafield[];
  handle: string;
  shopifyId: string;
  translations?: Translation[];
  description: string;
  productsCount: number;
  products: Product[];
  image?: any;
}

export interface TranslationNode {
  node: {
    resourceId: string;
    translations: Translation[];
  };
}

export enum InventoryPolicy {
  DENY = "DENY",
  CONTINUE = "CONTINUE",
}

export enum LevelOfMembership {
  MDRT = "MDRT",
  COT = "COT",
  TOT = "TOT",
}

export interface PageInfo {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface AllFiltersValues {
  allTags?: string[];
  allProductTypes?: string[];
  allMembershipLevels?: string[];
  allMembershipTypes?: string[];
}

export interface CurrencySymbol {
  symbol: string;
  symbolAtEnd: boolean;
}

export interface VariantMetafield {
  name: string;
  data: string[];
}

export interface Filters {
  maxPrice?: string | null;
  minPrice?: string | null;
  productTypes: string[];
  sortKey?: string;
  tags?: string[];
  term?: string | null;
  vendors?: string[];
  handle?: string | null;
  options: ProductOptionWithTranslations[];
  collectionHandle?: string | null;
  cursors?: string | null;
}

export interface QueryFilters {
  maxPrice?: string;
  minPrice?: string;
  types?: string[];
  sortKey?: string;
  q?: string;
  handle?: string;
  cursors?: string | null;
}

export interface Filter {
  label: string;
  values: FilterValue[];
}

export interface FilterValue {
  count: number;
  label: string;
}

export interface CustomerProfileResponse {
  firstName: string;
  lastName: string;
  designation1: string;
  designation2: string;
  memberStatus: string;
  memberType: string;
  levelOfMembership: string;
}

export interface CustomerBase {
  fullName: string;
  personId: number;
  mdrtId: number;
  companyId: string;
}

export interface CustomerProfileViewModel extends AdditionalInfo {
  id: string;
  commaAfterName?: boolean;
  mainFullName: string;
}

export interface Dimension {
  value: number;
  unit: string;
}

export interface ContactInfo {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export interface ContactAndAddressInfo {
  fullName: string;
  email: string;
  phone: string;
}

export interface SelectedLineItemOption {
  name: string;
  value: string;
}
export interface CartItemVariant {
  availableForSale: boolean;
  compareAtPrice: { amount: string; currencyCode: string }
  id: string;
  image: {
    altText: string;
    height: number;
    id: string;
    originalSrc: string;
    src: string;
    width: number;
  } | null;
  price: MonetaryAmount;
  product: { handle: string; id: string };
  selectedOptions: SelectedLineItemOption[];
  sku: string;
  title: string;
  unitPrice: MonetaryAmount;
  unitPriceMeasurement: any;
  weight: number;
  requiresShipping: boolean;
}

export interface Attribute {
  key: string;
  value?: string;
}

export interface RefreshCustomerInfoResponse {
  success: boolean;
  errorMessage?: string;
}

export interface OrderResponse {
  order: {
    id: string;
    lineItems: {
      edges: {
        node: {
          id: string;
          customAttributes: { key: string; value: string }[];
        };
      }[];
    };
  };
}

export interface CompanyPurchasesRequest {
  mdrtId: number;
  cursor: Cursor;
}

export interface CompanyPurchasesResponse {
  orders: OrderListItem[];
  pageInfo: PageInfoFull | null;
}

export interface OrderByIdRequest {
  mdrtId: number;
  personId: number;
  cursor: Cursor;
}