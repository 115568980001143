import { Metafield } from "../../types/shopify/global.types";
import { CustomerBase } from "../../utils/types";
import { CustomerYears } from "../mdrt/types";

export interface ShopifyCustomer {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  personId: number;
  mdrtid: number;
  metafields?: { nodes: Metafield[] };
  additionalInfo: AdditionalInfo;
  defaultAddress: ShopifyCustomerAddress;
  tags?: string[];
}

export interface AdditionalInfo extends CustomerBase {
  designation: string;
  levelOfMembership: string;
  memberStatus: string;
  memberType: string;
  originalFullName?: string;
  originalDesignation?: string;
  history: CustomerYears[];
}

export const additionalInfoFields = [
  "fullName",
  "personId",
  "mdrtId",
  "companyId",
  "designation",
  "levelOfMembership",
  "memberStatus",
  "memberType",
  "originalFullName",
  "originalDesignation",
  "history",
  "id",
];

export interface ShopifyCustomerAddress {
  id?: string;
  address1: string;
  address2?: string;
  city: string;
  countryCode?: string;
  country: string;
  zip: string;
  province?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  company?: string;
}

export interface ShippingAddress {
  address1: string;
  address2?: string;
  city: string;
  country: string;
  zip: string;
  province?: string;
  firstName: string;
  lastName: string;
  phone: string;
  company?: string;
}

export interface CustomerResponse {
  customer: ShopifyCustomer | null;
  userErrors?: UserError[];
}

export interface UserError {
  code?: string;
  field: string | string[];
  message: string;
}

export interface FileUploadRequest {
  imageFile: File;
  personId: number | undefined;
}

export interface FileUploadResponse {
  mediaShopifyId: string;
  fileName: string;
  fileSrc: string;
  errors?: UserError[];
}

export interface ContactAndAddressInfoCardResponse {
  address: ShippingAddress;
  updateDefaultAddress: boolean;
  email: string;
}

export interface MetafieldsSetRequest {
  metafields: Array<{
    namespace: string;
    key: string;
    ownerId: string;
    type: string;
    value: string;
  }>;
}

export interface MetafieldRemoveRequest {
  id: string;
}

export interface FileGetRequest {
  id: string;
}

export interface FileGetResponse {
  id: string;
  src: string;
}

export interface CheckoutCustomerAssociateRequest {
  cartId: string;
  customerAccessToken: string;
}

export interface UpdateShippingAddressRequestVariables {
  cartId: string;
  buyerIdentity: {
    deliveryAddressPreferences: DeliveryAddress[];
  };
}

export interface DeliveryAddress {
  deliveryAddress: ShippingAddress;
}

export interface GetCustomerOrdersRequest {
  customerAccessToken: string;
}

export interface LoginResponse {
  customerAccessToken: {
    accessToken: string;
    expiresAt: string;
  };
  customerUserErrors: [];
}

export interface LoginRequest {
  email: string;
  personId: number;
}

export interface ImpersonateResponse {
  success: boolean;
  message: string;
  logout?: boolean;
}

export interface CheckoutShippingAddressUpdateResponse {
  cart: {
    id: string;
  };
  userErrors?: UserError[];
}
