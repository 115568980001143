import { CartDto, LineDto } from "../../types/gql-dtos/cartDto";
import { CartItemVariant } from "../types";
import { CartItem } from "../../types/cart/cartItem";

export const mapCartDtoToCart = (cartDto: CartDto) => {
  return  {
    id: cartDto.cart.id,
    checkoutUrl: cartDto.cart.checkoutUrl,
    totalQuantity: cartDto.cart.totalQuantity,
    attributes: cartDto.cart.attributes,
    subtotalAmount: cartDto.cart.cost.subtotalAmount,
    totalAmount: cartDto.cart.cost.totalAmount,
    lines: mapCartItemDtoToCartItem(cartDto) as CartItem[]
  };
};


const mapCartItemDtoToCartItem = (cartDto: CartDto) => {
  return cartDto.cart.lines.edges.map(lineNode => {
   return {
     id: lineNode.node.id,
     title: lineNode.node.merchandise.product.title,
     quantity: lineNode.node.quantity,
     attributes: lineNode.node.attributes,
     requiresShipping: lineNode.node.merchandise.requiresShipping,
     variant: mapCartItemVariantDtoToCartItemVariant(lineNode) as CartItemVariant,
     totalPrice: lineNode.node.cost.totalAmount,
     totalDiscount: lineNode.node.discountAllocations.discountAmount,
     estimatedCost: lineNode.node.estimatedCost,
   };
  });
};

const mapCartItemVariantDtoToCartItemVariant = (lineNode: LineDto) => {
    return {
      availableForSale: lineNode.node.merchandise.availableForSale,
      compareAtPrice: lineNode.node.merchandise.compareAtPrice ? {
        amount: lineNode.node.merchandise.compareAtPrice.amount,
        currencyCode: lineNode.node.merchandise.compareAtPrice.currencyCode
      } : null,
      id: lineNode.node.merchandise.id,
      image: {
        altText: lineNode.node.merchandise.image?.altText,
        height: lineNode.node.merchandise.image?.height,
        id: lineNode.node.merchandise.image?.id,
        originalSrc: lineNode.node.merchandise.image?.originalSrc,
        src: lineNode.node.merchandise.image?.src,
        width: lineNode.node.merchandise.image?.width,
      },
      price: {
        amount: lineNode.node.merchandise.price.amount,
        currencyCode: lineNode.node.merchandise.price.currencyCode,
      },
      product: {
        handle: lineNode.node.merchandise.product.handle,
        id: lineNode.node.merchandise.product.id
      },
      selectedOptions: lineNode.node.merchandise.selectedOptions.map(option =>
        {
          return {
            name: option.name,
            value: option.value
          };
        }
      ),
      sku: lineNode.node.merchandise.sku,
      title: lineNode.node.merchandise.title,
      unitPrice: lineNode.node.merchandise.unitPrice ? {
        amount: lineNode.node.merchandise.unitPrice.amount,
        currencyCode: lineNode.node.merchandise.unitPrice.currencyCode,
      } : null,
      unitPriceMeasurement: lineNode.node.merchandise.unitPriceMeasurement,
      weight: lineNode.node.merchandise.weight,
      requiresShipping: lineNode.node.merchandise.requiresShipping,
    };
};