import { LOCAL_STORAGE_KEYS } from "../constants/storageKeys";
import { getPersonId } from "./accountUtils";
import { ShoppingCart } from "../../types/cart/shoppingCart";

export const getCurrentUserCheckoutId = () =>
  localStorage.getItem(LOCAL_STORAGE_KEYS.checkoutId(getPersonId()));

export const getAnonymousUserCheckoutId = () =>
  localStorage.getItem(LOCAL_STORAGE_KEYS.checkoutId());

export const setCurrentUserCheckoutValues = (checkoutId: string) => {
  localStorage.setItem(
    LOCAL_STORAGE_KEYS.checkoutId(getPersonId()),
    checkoutId,
  );
};

export const removeAnonymousUserCheckoutId = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.checkoutId());
};

export const isShippingRequired = (checkout: ShoppingCart) => {
  return checkout.lines.reduce((count, line) => {
    return line.requiresShipping ? count + 1 : count;
  }, 0) > 0;
};

export const goToCheckout = (checkoutUrl: string) => {
  window.location.href = checkoutUrl.replace(
    process.env.GATSBY_SHOPIFY_STORE_URL_PROXY ?? "",
    process.env.GATSBY_CHECKOUT_REDIRECT_URL ??
    process.env.GATSBY_SHOPIFY_STORE_URL ??
    "",
  );
};