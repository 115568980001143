export const updateLineItemsMutation = `
mutation CartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
    cartLinesUpdate(cartId: $cartId, lines: $lines) {
        cart {
            checkoutUrl
            id
            totalQuantity
            cost {
                subtotalAmount {
                    amount
                    currencyCode
                }
                totalAmount {
                    amount
                    currencyCode
                }
            }
            attributes {
                key
                value
            }
            lines(first: 250) {
                edges {
                    node {
                        ... on CartLine {
                            id
                            merchandise {
                                ... on ProductVariant {
                                    availableForSale
                                    currentlyNotInStock
                                    id
                                    quantityAvailable
                                    requiresShipping
                                    sku
                                    taxable
                                    title
                                    weight
                                    weightUnit
                                    image {
                                        altText
                                        src
                                        height
                                        id
                                        originalSrc
                                        width
                                    }
                                    product {
                                        id
                                        title
                                        handle
                                    }
                                    compareAtPrice {
                                        amount
                                        currencyCode
                                    }
                                    price {
                                        amount
                                        currencyCode
                                    }
                                    unitPrice {
                                        amount
                                        currencyCode
                                    }
                                    unitPriceMeasurement {
                                        measuredType
                                        quantityUnit
                                        quantityValue
                                        referenceUnit
                                        referenceValue
                                    }
                                    selectedOptions {
                                        name
                                        value
                                    }
                                }
                            }
                            quantity
                            attributes {
                                key
                                value
                            }
                            cost {
                                totalAmount {
                                    amount
                                    currencyCode
                                }
                                subtotalAmount {
                                    amount
                                    currencyCode
                                }
                                amountPerQuantity {
                                    amount
                                    currencyCode
                                }
                            }
                            discountAllocations {
                                targetType
                                discountedAmount {
                                    amount
                                    currencyCode
                                }
                            }
                            estimatedCost {
                                totalAmount {
                                    amount
                                    currencyCode
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
`;
