import React, {
  createContext,
  ReactNode,
  useEffect,
  useReducer,
  useState,
} from "react";
import * as Sentry from "@sentry/react";
import { MetafieldsService } from "../../services/shopify/metafields.service";
import { SESSION_STORAGE_KEYS } from "../../utils/constants/storageKeys";
import {
  getSessionCustomerProfile,
  setSessionCustomerProfile,
} from "../../utils/sessionStorage";
import { Attribute, CustomerProfileViewModel } from "../../utils/types";
import { CartItem } from "../../types/cart/cartItem";
import { PlaqueInfo } from "../../components/Plaque/types";
import { ShopifyCustomerService } from "../../services/shopify/customers.service";
import {
  ToastInfo,
  ToastVariant,
} from "../../components/ToastsController/types";
import { useTranslation } from "gatsby-plugin-react-i18next";
import CartIcon from "../../icons/cart";
import { CustomAttributeKeys } from "../../utils/constants/constants";
import { plaqueInfoReducer } from "../../utils/helper/setPlaqueInfoHelpers";
import { getCurrentShopifyCustomer } from "../../services/internal/shopify/get-current-customer";
import {
  pushDataLayerAddRemoveCartItem,
  pushDataLayerAddToCart,
} from "../../utils/helper/googleAnalytics";
import {
  AdditionalInfo,
  additionalInfoFields,
} from "../../services/shopify/types";
import { getWishlist } from "../../services/internal/wishlist";
import {
  ShopifyProduct,
  ShopifyProductVariant,
} from "../../types/internal.types";
import { pick } from "lodash";
import { useOnlineStateLogger } from "./useOnlineStateLogger";
import { setCurrentUserCheckoutValues } from "../../utils/helper/checkoutUtils";
import { wrapPromiseWithRetries } from "../../utils/wrapPromiseWithRetries";
import { createStorefrontApiClient } from "@shopify/storefront-api-client";
import { ShoppingCart } from "../../types/cart/shoppingCart";
import addCartLinesMutation from "../../graphql/mutations/addCartLinesMutation";
import { CartItemToAdd } from "../../types/cart-item-query-variables/cartItemToAdd";
import { removeCartLinesMutation } from "../../graphql/mutations/removeCartLinesMutation";
import { updateLineItemsMutation } from "../../graphql/mutations/updateLineItemsMutation";
import { CartItemToUpdate } from "../../types/cart-item-query-variables/cartItemToUpdate";
import { mapCartDtoToCart } from "../../utils/mappers/cartDtoToCart";
import useInitializeCheckout from "./useInitializeCheckout";

export const client = createStorefrontApiClient({
  storeDomain:
    `https://${process.env.GATSBY_SHOPIFY_STORE_URL}/api/${process.env.GATSBY_SHOPIFY_API_VERSION}/graphql.json` ??
    "",
  apiVersion: process.env.GATSBY_SHOPIFY_API_VERSION ?? "",
  publicAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN ?? "",
});

const cart: ShoppingCart = {
  checkoutUrl: "",
  id: "",
  totalQuantity: 0,
  lines: Array<CartItem>(),
  subtotalAmount: {
    amount: "",
    currencyCode: "",
  },
  totalAmount: {
    amount: "",
    currencyCode: "",
  },
  attributes: [],
};

const plaqueInfo: PlaqueInfo = {
  name: "",
  designation: "",
  year: "",
  yearArr: [],
  comma: true,
  photo: null,
  yearSum: "",
  templateUrl: "",
  isGrayscale: false,
};

const customerProfile: AdditionalInfo = {
  fullName: "",
  designation: "",
  levelOfMembership: "",
  memberStatus: "",
  memberType: "",
  history: [],
  personId: 0,
  mdrtId: 0,
  companyId: "",
};

const defaultValues = {
  cart: [],
  isOpen: false,
  loading: false,
  setLoading: (loading: boolean) => {
    return;
  },
  onOpen: () => null,
  onClose: () => null,
  addVariantToCart: (
    product: ShopifyProduct,
    variant: ShopifyProductVariant,
    quantity: string,
    isMaxQuantitySurpassed: boolean,
    fullName: string,
    designation: string,
    initials: string,
    photoSrc: string | null,
    photoName: string | null,
    useCommaAfterName?: boolean,
    year?: string,
    ringSize?: string,
    plaqueTemplateDataUrl?: string,
    purchaseFor?: AdditionalInfo
  ): Promise<void> => {
    return Promise.resolve();
  },
  removeLineItem: (
    item: CartItem,
    id: string,
    itemId: string
  ): Promise<void> => {
    return Promise.resolve();
  },
  updateLineItem: (
    checkoutId: string,
    lineItemId: string,
    quantity: string
  ): Promise<void> => {
    return Promise.resolve();
  },
  setAuthenticationLoading: (isLoading: boolean) => {
    return;
  },
  isAuthenticatingUser: true,
  toastMessages: [] as ToastInfo[],
  addToast: (toast: ToastInfo) => {
    return;
  },
  removeToast: (toastId: number) => {
    return;
  },
  client,
  checkout: cart,
  plaqueInfo: plaqueInfo,
  setPlaqueInfo: (plaqueInfoReducer: any) => {
    return;
  },
  additionalInfoLoading: false,
  setAdditionalInfoLoading: (additionalInfoLoading: boolean) => {
    return;
  },
  wishlist: undefined as string[] | undefined,
  setWishlist: (shopifyIds: string[]) => {
    return;
  },
  isGlobalSearchOpen: false,
  setIsGlobalSearchOpen: (isGlobalSearchOpen: boolean) => {
    return;
  },
  isImpersonating: false,
  customerProfile,
  setCustomerProfile: (profile: AdditionalInfo) => {
    return;
  },
  customerAccessToken: null as string | null,
  setCustomerAccessToken: (token: string) => {
    return;
  },
  googleApiLoaded: false,
  setGoogleApiLoaded: (googleApiLoaded: boolean) => {
    return;
  },
};

export const StoreContext = createContext(defaultValues);

export const StoreProvider = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation();
  const [cart, setCart] = useState<ShoppingCart>(defaultValues.checkout);

  const [loading, setLoading] = useState(false);
  const [isAuthenticatingUser, setIsAuthenticatingUser] = useState(
    defaultValues.isAuthenticatingUser
  );
  const [additionalInfoLoading, setAdditionalInfoLoading] = useState(false);
  const [toastMessages, setToastMessages] = useState<ToastInfo[]>(
    defaultValues.toastMessages
  );
  const [plaqueInfo, setPlaqueInfo] = useReducer(
    plaqueInfoReducer,
    defaultValues.plaqueInfo
  );
  const [isGlobalSearchOpen, setIsGlobalSearchOpen] = useState(false);
  const [isImpersonating, setIsImpersonating] = useState(
    defaultValues.isImpersonating
  );
  const [customerProfile, setCustomerProfile] = useState<AdditionalInfo>(
    defaultValues.customerProfile
  );
  const [wishlist, setWishlist] = useState(defaultValues.wishlist);
  const [customerAccessToken, setCustomerAccessToken] = useState<string | null>(
    defaultValues.customerAccessToken
  );
  const [googleApiLoaded, setGoogleApiLoaded] = useState<boolean>(false);

  const addToast = (toast: ToastInfo) =>
    setToastMessages((prev) => [toast, ...prev]);
  const removeToast = (id: number) =>
    setToastMessages((prev) => prev.filter((toast) => toast.id !== id));

  const setAuthenticationLoading = (isLoading: boolean) =>
    setIsAuthenticatingUser(isLoading);

  const setCheckoutItem = (cart: ShoppingCart) => {
    if (window) {
      setCurrentUserCheckoutValues(cart.id);
    }

    setCart(cart);
  };

  useInitializeCheckout(
    client,
    setLoading,
    setCheckoutItem,
    isAuthenticatingUser,
    cart
  );

  useEffect(() => {
    setIsImpersonating(
      !!sessionStorage.getItem(SESSION_STORAGE_KEYS.impersonatePersonId)
    );
    setWishlist(getWishlist());
  }, []);

  useOnlineStateLogger();

  const addVariantToCart = async (
    product: ShopifyProduct,
    variant: ShopifyProductVariant,
    quantity: string,
    isMaxQuantitySurpassed: boolean,
    fullName: string,
    designation: string,
    initials: string,
    photoSrc: string | null,
    photoName: string | null,
    useCommaAfterName?: boolean,
    year?: string,
    ringSize?: string,
    plaqueTemplateDataUrl?: string,
    purchaseFor?: AdditionalInfo
  ) => {
    setLoading(true);

    const checkoutID = cart.id;

    const customAttributes: Attribute[] = [
      {
        key: CustomAttributeKeys.FULL_NAME,
        value: fullName,
      },
      {
        key: CustomAttributeKeys.DESIGNATION,
        value: designation,
      },
      {
        key: CustomAttributeKeys.INITIALS,
        value: initials,
      },
      {
        key: CustomAttributeKeys.YEAR,
        value: year ?? "",
      },
      {
        key: CustomAttributeKeys.PRODUCT_TYPE,
        value: product.productType,
      },
      {
        key: CustomAttributeKeys.RING_SIZE,
        value: ringSize ?? "",
      },
    ].filter((attribute) => attribute.value.length > 0);

    const customer = getSessionCustomerProfile() as CustomerProfileViewModel;
    if (customer && customer.personId) {
      const customerAccessToken = sessionStorage.getItem(
        SESSION_STORAGE_KEYS.customerAccessToken
      );
      if (!customerAccessToken) {
        const shopifyCustomersService = new ShopifyCustomerService();
        const customer = await getCurrentShopifyCustomer();
        if (customer?.email) {
          const loginResult = await shopifyCustomersService.login({
            email: customer.email,
            personId: customer.personId,
          });

          if (loginResult.customerAccessToken?.accessToken) {
            sessionStorage.setItem(
              SESSION_STORAGE_KEYS.customerAccessToken,
              loginResult.customerAccessToken?.accessToken
            );
          }
        }
      }

      setCustomerAccessToken(
        sessionStorage.getItem(SESSION_STORAGE_KEYS.customerAccessToken)
      );

      if (!purchaseFor || purchaseFor.personId === customer.personId) {
        await updateCustomerProfileIfNeeded(customer, fullName, designation);
      }

      if (photoSrc) {
        customAttributes.push({
          key: CustomAttributeKeys.MEMBER_PHOTO,
          value: photoSrc,
        });
      }
      if (photoName) {
        customAttributes.push({
          key: CustomAttributeKeys.UPLOADED_PHOTO,
          value: photoName,
        });
      }
      if (plaqueTemplateDataUrl?.length) {
        customAttributes.push({
          key: CustomAttributeKeys.PLAQUE_TEMPLATE_URL,
          value: plaqueTemplateDataUrl,
        });
      }
      if (useCommaAfterName !== undefined) {
        customAttributes.push({
          key: CustomAttributeKeys.USE_COMMA_AFTER_NAME,
          value: JSON.stringify(useCommaAfterName),
        });
      }

      if (purchaseFor) {
        customAttributes.push({
          key: CustomAttributeKeys.RECIPIENT,
          value: `${purchaseFor.fullName} (${purchaseFor.mdrtId}; ${purchaseFor.companyId})`,
        });
      }
    }

    const newCartItem: CartItemToAdd[] = [
      {
        quantity: parseInt(quantity, 10),
        merchandiseId: variant.storefrontId,
        attributes: customAttributes,
      },
    ];

    return wrapPromiseWithRetries(
      client
        .request(addCartLinesMutation, {
          variables: {
            cartId: checkoutID,
            lineItems: newCartItem,
          },
        })
        .then((res) => {
          const { data: cartData } = res;
          const cart = mapCartDtoToCart(cartData.cartLinesAdd);
          setCart(cart as ShoppingCart);
          addToast({
            id: Date.now(),
            message: isMaxQuantitySurpassed
              ? t("Maximum available quantity has been added to your cart.")
              : t("Your cart has been updated."),
            icon: <CartIcon />,
            variant: ToastVariant.success,
          });
          pushDataLayerAddToCart(product, variant, parseInt(quantity, 10));
        })
    )
      .catch((e) => {
        Sentry.captureException(e);
        addToast({
          id: Date.now(),
          message: t("Failed to update your cart, please try again."),
          icon: <CartIcon />,
          variant: ToastVariant.error,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const removeCartLine = async (
    item: CartItem,
    checkoutID: string,
    lineItemID: string
  ) => {
    setLoading(true);

    const removeLineResponse = await wrapPromiseWithRetries(
      client.request(removeCartLinesMutation, {
        variables: {
          cartId: checkoutID,
          lineIds: [lineItemID],
        },
      })
    );

    const updatedCart = mapCartDtoToCart(
      removeLineResponse.data.cartLinesRemove
    );

    setCart(updatedCart as ShoppingCart);
    setLoading(false);
    pushDataLayerAddRemoveCartItem(item, false, item?.quantity);
  };

  const updateCartLine = async (
    cartId: string,
    lineId: string,
    quantity: string
  ): Promise<void> => {
    setLoading(true);

    const cartLineToUpdate: CartItemToUpdate[] = [
      { id: lineId, quantity: parseInt(quantity, 10) },
    ];

    const updateLineResponse = await wrapPromiseWithRetries(
      client.request(updateLineItemsMutation, {
        variables: {
          cartId: cartId,
          lines: cartLineToUpdate,
        },
      })
    );

    const updatedCart = mapCartDtoToCart(
      updateLineResponse.data.cartLinesUpdate
    );

    setCart(updatedCart as ShoppingCart);
    setLoading(false);
  };

  const updateCustomerProfileIfNeeded = async (
    customer: CustomerProfileViewModel,
    fullName: string,
    designation: string
  ) => {
    const metafields: {
      namespace: string;
      key: string;
      ownerId: string;
      type: string;
      value: string;
    }[] = [];

    let needsUpdate = false;
    const profile = pick(customer, additionalInfoFields);
    if (fullName.length && fullName !== profile.fullName) {
      profile.fullName = fullName;
      needsUpdate = true;
    }
    if (designation.length && designation !== profile.designation) {
      profile.designation = designation;
      needsUpdate = true;
    }

    if (needsUpdate) {
      metafields.push({
        namespace: "customer",
        key: "additionalInfo",
        ownerId: customer.id,
        type: "json",
        value: JSON.stringify(profile),
      });

      profile.mainFullName = customer.mainFullName;
      setSessionCustomerProfile(profile as CustomerProfileViewModel);
    }

    if (metafields.length > 0) {
      const metafieldsService = new MetafieldsService();
      await metafieldsService.setMetafieldsOfCustomer({
        metafields,
      });
    }
  };

  const value = {
    ...defaultValues,
    addVariantToCart,
    removeLineItem: removeCartLine,
    updateLineItem: updateCartLine,
    setAuthenticationLoading,
    isAuthenticatingUser,
    wishlist,
    setWishlist,
    checkout: cart,
    loading,
    toastMessages,
    addToast,
    removeToast,
    setPlaqueInfo,
    plaqueInfo,
    additionalInfoLoading,
    setAdditionalInfoLoading,
    setIsGlobalSearchOpen,
    isGlobalSearchOpen,
    setLoading,
    isImpersonating,
    customerProfile,
    setCustomerProfile,
    customerAccessToken,
    setCustomerAccessToken,
    googleApiLoaded,
    setGoogleApiLoaded,
  };

  return (
    <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
  );
};
