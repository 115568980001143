import { Dispatch, SetStateAction, useEffect } from "react";
import {
  getAnonymousUserCheckoutId,
  getCurrentUserCheckoutId,
  removeAnonymousUserCheckoutId,
  setCurrentUserCheckoutValues,
} from "../../utils/helper/checkoutUtils";
import { getPersonId } from "../../utils/helper/accountUtils";
import { wrapPromiseWithRetries } from "../../utils/wrapPromiseWithRetries";
import cartByIdQuery from "../../graphql/queries/getCart";
import { mapCartDtoToCart } from "../../utils/mappers/cartDtoToCart";
import { ShoppingCart } from "../../types/cart/shoppingCart";
import { cartNoteUpdateMutation } from "../../graphql/mutations/cartNoteUpdateMutation";
import createCartMutation from "../../graphql/mutations/createCartMutation";
import * as Sentry from "@sentry/react";
import { StorefrontApiClient } from "@shopify/storefront-api-client";

const useInitializeCheckout = (
  client: StorefrontApiClient,
  setLoading: Dispatch<SetStateAction<boolean>>,
  setCheckoutItem: (cart: ShoppingCart) => void,
  isAuthenticatingUser: boolean,
  cart: ShoppingCart
) => {
  useEffect(() => {
    const initializeCheckout = async () => {
      const anonymousCartId = getAnonymousUserCheckoutId();
      const currentUserCartId = getCurrentUserCheckoutId();

      const isAnonymousUserCartTransferableToCurrentUser = async () => {
        if (!currentUserCartId) {
          return true;
        }

        if (!anonymousCartId || !getPersonId()) {
          return false;
        }

        const anonymousUserCartResponse = await wrapPromiseWithRetries(
          client.request(cartByIdQuery, {
            variables: { cartId: anonymousCartId },
          })
        );

        const anonymousUserCheckout = mapCartDtoToCart(
          anonymousUserCartResponse.data
        );

        return (
          anonymousUserCheckout != null &&
          anonymousUserCheckout.lines.length > 0
        );
      };

      if (
        (await isAnonymousUserCartTransferableToCurrentUser()) &&
        anonymousCartId
      ) {
        removeAnonymousUserCheckoutId();
        setCurrentUserCheckoutValues(anonymousCartId);
      }

      const existingCartId = getCurrentUserCheckoutId();

      setLoading(true);
      if (existingCartId && existingCartId !== `null`) {
        try {
          const existingCartResponse = await wrapPromiseWithRetries(
            client.request(cartByIdQuery, {
              variables: { cartId: existingCartId },
            })
          );

          const existingCart = mapCartDtoToCart(existingCartResponse.data);

          if (existingCart.id) {
            setCheckoutItem(existingCart as ShoppingCart);

            await wrapPromiseWithRetries(
              client.request(cartNoteUpdateMutation, {
                variables: {
                  cartId: existingCart.id,
                  note: `Person ID: ${getPersonId()}`,
                },
              })
            );

            setLoading(false);
            return;
          }
        } catch (e) {
          //do nothing
        }
      }

      const newEmptyCartTemplate = {
        variables: {
          input: {
            lines: [],
          },
        },
      };

      const createCartClientResponse = await wrapPromiseWithRetries(
        client.request(createCartMutation, newEmptyCartTemplate)
      );

      const userCart = mapCartDtoToCart(
        createCartClientResponse.data.cartCreate
      );

      await wrapPromiseWithRetries(
        client.request(cartNoteUpdateMutation, {
          variables: {
            cartId: userCart.id,
            note: `Person ID: ${getPersonId()}`,
          },
        })
      );

      setCheckoutItem(userCart as ShoppingCart);
      setLoading(false);
    };

    if (window && !isAuthenticatingUser && !cart?.id) {
      initializeCheckout().catch((e) => Sentry.captureException(e));
    }
  }, [getPersonId(), window, isAuthenticatingUser]);
};

export default useInitializeCheckout;
